import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Software/Home_Automation/PrimaryBoxMQTT';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import NavButtons from 'components/Software/Home_Automation/NavButtons';
import ForumBox from 'components/Software/Home_Automation/Athom_Homey/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Athom Homey Smarthome",
  "path": "/Software/Home_Automation/Athom_Homey/MQTTv5/",
  "dateChanged": "2022-07-18",
  "author": "Mike Polinowski",
  "excerpt": "Using the Athom Homey base station to let your INSTAR camera interact with wireless Zigbee, Z-Wave or IR sensors, signalling devices and smart buttons. Automate your cameras operation in a smarthome environment.",
  "social": "/images/Search/P_SearchThumb_Homey.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Homey_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Homey"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='Athom Homey Smarthome' dateChanged='2022-07-18' author='Mike Polinowski' tag='INSTAR IP Camera' description='Using the Athom Homey base station to let your INSTAR camera interact with wireless Zigbee, Z-Wave or IR sensors, signalling devices and smart buttons. Automate your cameras operation in a smarthome environment.' image='/images/Search/P_SearchThumb_Homey.png' twitter='/images/Search/P_SearchThumb_Homey.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Home_Automation/Athom_Homey/' locationFR='/fr/Home_Automation/Athom_Homey/' crumbLabel="Homey" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "home-automation-mqttv5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#home-automation-mqttv5",
        "aria-label": "home automation mqttv5 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Home Automation (MQTTv5)`}</h2>
    <p>{`This guide uses the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/"
      }}>{`MQTTv5 API`}</a>{`. For Full HD cameras please replace the MQTT topics with the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTT_API/"
      }}>{`MQTTv3 API`}</a>{`. If you don't use MQTT in your smarthome system please refer to our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/"
      }}>{`HTTP API (CGI) documentation`}</a>{` - the `}<em parentName="p">{`CGI version`}</em>{` of this guide can be found `}<a parentName="p" {...{
        "href": "/en/Software/Home_Automation/Athom_Homey/"
      }}>{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "athom-homey-smarthome",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#athom-homey-smarthome",
        "aria-label": "athom homey smarthome permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Athom Homey Smarthome`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#home-automation-mqttv5"
        }}>{`Home Automation MQTTv5`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#athom-homey-smarthome"
            }}>{`Athom Homey Smarthome`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#introduction"
        }}>{`Introduction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#how-does-it-work"
        }}>{`How does it work`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-external-sensors"
            }}>{`Use external Sensors`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#activate-night-vision-only-when-motion-is-detected"
            }}>{`Activate night vision only when motion is detected`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#approach-different-positions-according-to-a-schedule"
            }}>{`Approach different positions according to a schedule`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-different-detection-areas-for-each-position"
            }}>{`Use different Detection Areas for each Position`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-different-sensitivities-for-day-and-night"
            }}>{`Use different sensitivities for day and night`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#turn-off-all-cameras-when-you-arrive-home"
            }}>{`Turn off all Cameras when you arrive home`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-external-sensors-to-turn-your-camera-when-motion-is-detected"
            }}>{`Use external Sensors to turn your Camera when Motion is detected`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-external-sensors-to-trigger-alarm-recordings"
            }}>{`Use external Sensors to trigger Alarm Recordings`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#interconnect-your-cameras"
            }}>{`Interconnect your Cameras`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#scan-for-noise-sources"
            }}>{`Scan for Noise Sources`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#manually-disable-all-alarm-functions-for-all-your-cameras"
            }}>{`Manually disable all alarm functions for all your cameras`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#switch-between-pir-and-software-motion-detection"
            }}>{`Switch between PIR and Software Motion Detection`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.athom.com/homey/"
      }}>{`Homey`}</a>{` is a wireless gateway that allows you to communicate with wireless smart devices. You can control devices from anywhere using the free `}<a parentName="p" {...{
        "href": "https://play.google.com/store/apps/details?id=app.homey&hl=en"
      }}>{`Homey app`}</a>{`.`}</p>
    <p>{`With over 50,000 devices supported, your current devices will most likely already work with Homey.
Homey learns new devices by `}<a parentName="p" {...{
        "href": "https://apps.athom.com"
      }}>{`installing Apps`}</a>{` — just like a smartphone.`}</p>
    <p>{`You can control your devices manually, with schedules and system variables or with intelligent programs called `}<strong parentName="p">{`Flows`}</strong>{`. Those are simple `}<strong parentName="p">{`If / Then`}</strong>{` clauses that you can set up from your smartphone - very similar to the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_IFTTT/"
      }}>{`IFTTT Cloud Service`}</a>{`.`}</p>
    <p>{`In the following paragraphs we want to show you how to connect external wireless sensors through the Homey system with your INSTAR IP camera, set up time schedules for your surveillance and optimize your camera operation.`}</p>
    <p>{`If you are looking for a deep dive into setting up your Homey automation with an INSTAR IP camera, switch to our `}<a parentName="p" {...{
        "href": "/en/Software/Other_Platforms/Athom_Homey/"
      }}>{`FULL GUIDE`}</a>{` instead.`}</p>
    <h2 {...{
      "id": "how-does-it-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-does-it-work",
        "aria-label": "how does it work permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How does it work`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
        }}>{`How to Configure the INSTAR MQTT Broker/Client`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
        }}>{`The INSTAR MQTT Service`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/"
        }}>{`The INSTAR MQTTv5 API Documentation`}</a></li>
    </ul>
    <h3 {...{
      "id": "1-use-external-sensors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#1-use-external-sensors",
        "aria-label": "1 use external sensors permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Use external Sensors`}</h3>
    <p>{`Triggering an alarm on the camera by an event registered by your home automation system:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/913a83641d3246de2d48da28c34ac01f/29007/Homey-EN_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1UlEQVQoz12Oy47TMBRA83n8Cz/CCIkte1jMYpCQYAcSTNEwfcyjbUhL2mnTpkncPBonduq3E6NkCqqwzsLSPUf3WsY0HYYzLoQ0xjRNjcoSVdXODzZrDxbtU6odHasqTdOyLJVSxhirs40xJsvKDGJsDNJN3pg918v1Q88euZDErAaIRIjwui5gybsdbazwTuGdxjsGtyz3JNzKYivgRhZeGNljz4HZ5pgsCvAbxUuJApp7EvnPlSWSyV/GCq4EjBTai9zXqQ2C2XI7r7OJzGyVjeV+IEBfpPa/xBLprw6Hx2NdRZoda0EVKep8HgLHjxyd2TyZ8nyl52/l+EIcFm3fVVb3O0F3d9X653EzwE83dfw4850IzHUyMci9//Fp8fGlcS74wRXp9Nm3RDI9kdoiHAmvx72eCIcimfor+/bmGgcPJre/f37/5c0LM3vF0nl3c5tYFPRboluajOj0Ax1c0sElmVzReJhGa9ddoODO+NePX98tvr1utlcEDE8J6Fuyrs7AvCGiIdoQoXEOD7hCTODGkDjxqaiUYee+JXV5DmDiwKowcEOwAnsvSX0QexF4YhwqXUpVnMuWbtg5hZRUC0pLQhHnmIuKMcQ4lpr8Z+qG/QGZU1vn5Tiw3QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/913a83641d3246de2d48da28c34ac01f/e4706/Homey-EN_01.avif 230w", "/en/static/913a83641d3246de2d48da28c34ac01f/d1af7/Homey-EN_01.avif 460w", "/en/static/913a83641d3246de2d48da28c34ac01f/7f308/Homey-EN_01.avif 920w", "/en/static/913a83641d3246de2d48da28c34ac01f/e1c99/Homey-EN_01.avif 1380w", "/en/static/913a83641d3246de2d48da28c34ac01f/2babf/Homey-EN_01.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/913a83641d3246de2d48da28c34ac01f/a0b58/Homey-EN_01.webp 230w", "/en/static/913a83641d3246de2d48da28c34ac01f/bc10c/Homey-EN_01.webp 460w", "/en/static/913a83641d3246de2d48da28c34ac01f/966d8/Homey-EN_01.webp 920w", "/en/static/913a83641d3246de2d48da28c34ac01f/445df/Homey-EN_01.webp 1380w", "/en/static/913a83641d3246de2d48da28c34ac01f/fad48/Homey-EN_01.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/913a83641d3246de2d48da28c34ac01f/81c8e/Homey-EN_01.png 230w", "/en/static/913a83641d3246de2d48da28c34ac01f/08a84/Homey-EN_01.png 460w", "/en/static/913a83641d3246de2d48da28c34ac01f/c0255/Homey-EN_01.png 920w", "/en/static/913a83641d3246de2d48da28c34ac01f/b1001/Homey-EN_01.png 1380w", "/en/static/913a83641d3246de2d48da28c34ac01f/29007/Homey-EN_01.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/913a83641d3246de2d48da28c34ac01f/c0255/Homey-EN_01.png",
              "alt": "Homey",
              "title": "Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/pushalarm     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "2-activate-night-vision-only-when-motion-is-detected",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2-activate-night-vision-only-when-motion-is-detected",
        "aria-label": "2 activate night vision only when motion is detected permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Activate night vision only when motion is detected`}</h3>
    <p>{`Activate your camera's night vision on a schedule or triggered by an external/camera sensor integrated into the home automation system:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a6e0855e6506bd149393f565e34e8671/29007/Homey-EN_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACZ0lEQVQoz2Pg2vfd5eJft/O/7c/+Uj32Q+nId4UjPyX2f2bY9E5l/2e1Yz+MT/9xOf/b5swvjwsg0u38H9Pj33h3vmfY8oGBd/Jeham7lWceUJt7WHveYbNFRzRm7w/dddt67zPXXff1Fx4R6t+uNnOfyuwDOnMPaM89qDR9r836q1Z7X6ivv8kQ4OXRVF/n5uSop6VlrK+no6nh5ux89PDh/bv2bVy9Oiclqa6y3NfT3c7KwsHG2tHGysrUaO+ObccPHFq9eDHDnHlT3v//VD9pqoqti5mHr5KVY3tP39fvv5auWFvfWL9x8/7vz771d3VruQfY+IVoOnsXlld9/Pxt8/Y95VWVDDGx0YuWLwoMCdE3NjG3sdU2NM7Mzjt4+MTESVPdnBxqa+rnz5ofGx3t4uzs7eXl4OISl5K6a+e+5UtXGhvoMhgaGurq6BoZGpqZmpqamBgbGdrb2bd0dAd6+SQFBoSEhKipqxkZGZuamRmbmlqYmPiamE6sqk2IjnU1NmQwMzMztzA3MwPphQIzUyMNTX9by77+zoiICCNDI3Nzc2tT0wBLyyQzsxJDQy91dXcLs6rKIgZTVGBmZmpsbOxmZ1tRU9o9oSssNFRfX9/S0jLQwyPUzi7RxibRwsLZ3Ky8qqSqvgJTs5mRoaGbl1dZSnBfW3V4VJSGhoafn1//hAnGRkae1tY+FuZ2Ts7lmVHVBcnomk1NQTY7OTnEuRtV5iaGhUcYGhqGhISkp6c7OjnZ2tiYm5jY2Nom+FglBTlh0QwGJrq6OoHgADM1NXV2dra2tnZycrK2tjaBhquRsZEhAGSx9vY3z4AkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6e0855e6506bd149393f565e34e8671/e4706/Homey-EN_02.avif 230w", "/en/static/a6e0855e6506bd149393f565e34e8671/d1af7/Homey-EN_02.avif 460w", "/en/static/a6e0855e6506bd149393f565e34e8671/7f308/Homey-EN_02.avif 920w", "/en/static/a6e0855e6506bd149393f565e34e8671/e1c99/Homey-EN_02.avif 1380w", "/en/static/a6e0855e6506bd149393f565e34e8671/2babf/Homey-EN_02.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a6e0855e6506bd149393f565e34e8671/a0b58/Homey-EN_02.webp 230w", "/en/static/a6e0855e6506bd149393f565e34e8671/bc10c/Homey-EN_02.webp 460w", "/en/static/a6e0855e6506bd149393f565e34e8671/966d8/Homey-EN_02.webp 920w", "/en/static/a6e0855e6506bd149393f565e34e8671/445df/Homey-EN_02.webp 1380w", "/en/static/a6e0855e6506bd149393f565e34e8671/fad48/Homey-EN_02.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6e0855e6506bd149393f565e34e8671/81c8e/Homey-EN_02.png 230w", "/en/static/a6e0855e6506bd149393f565e34e8671/08a84/Homey-EN_02.png 460w", "/en/static/a6e0855e6506bd149393f565e34e8671/c0255/Homey-EN_02.png 920w", "/en/static/a6e0855e6506bd149393f565e34e8671/b1001/Homey-EN_02.png 1380w", "/en/static/a6e0855e6506bd149393f565e34e8671/29007/Homey-EN_02.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a6e0855e6506bd149393f565e34e8671/c0255/Homey-EN_02.png",
              "alt": "Homey",
              "title": "Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`features/nightvision/autoled	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`The activation of the night vision in case of an alarm is of course only possible if the camera has an internal PIR sensor for motion detection, or if you use the alarm signal of an external sensor in home automation to "focus" the camera. The advantage - the camera is inconspicuous at night and the IR light attracts no insects / spiders. And the power consumption is of course also pressed.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "3-approach-different-positions-according-to-a-schedule",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#3-approach-different-positions-according-to-a-schedule",
        "aria-label": "3 approach different positions according to a schedule permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Approach different positions according to a schedule`}</h3>
    <p>{`Scheduled day and night position - Guard the front door when I'm not home, but turn the camera to my carport when I'm back from work:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7bd8b982d0e624e936c7f4c5392d8e17/29007/Homey-EN_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACj0lEQVQoz1XO708ScQDH8fsPelAPavXAqSvHOuCO47jDdFhDnNg0yPkDkPA480dTGSUqTEIqXXO21SNbm1tt/gH1yOUjS9SimFNTE1jOHyv5YUACx933vs3Wg3o9f+/zQZ4MVi/OlLi7DJPegbGBrsfDvX4n22fSdxqrPD2M31E/6TilJaSubqazxWi9WW9rMtRp1NKic7Li84ibqfExckfTteH2BjfbeNeku9eqdVlqnM2aR/1tLsbQoTutV5Q1a8gblWS1EtXikjq1Qn7hjLL4LBJe/bT5Obi9srwVCqyFPqwHF7ZCgZXA3NybmfBasKvdhl0q05A4Xlb6bHLC2XtHUlJUpSbGfW57mwkR4V8CEI9z3FEmm4Ew8ysRjKym8oUOlkVlMjmuuF7fcJTKzC8sS9Qas9Wyn96dmJpCOC7P8wUA+Ewms7Qc3Nz6CkWQyx7n+EIqFrtltUplMhzH9bX6+feLs7Nz5Wo1qVSaLeZanQ7hCwVwQgAAhCPRH3v7kOchhDuh0LuZV3bWjqIoTVEkSXrv+3r7+nEMIylKUlraUHkF4XlBFCGAIgAAiiL4U4aXlt4+f5pIHTB29jIqpehylUpFURSGYUqSVKKoUVPh9Q4hXDadj0e49CEAosDzAs+LIoyGPsZT33/mkh2MrYq4qKvACYKgKIqmaQVBaCiVa8g54h9Bjg+jfC6dT3wTuCwQIRAEAAQRwkTgZXxvfdjZ2dZivG0xVNKEkjwZVxLEVV3NYHerz9WDcOkYFIGQPQI8J0J4ch7CZPwg+MKxEXjd18PaGYa1mQkMlcnlmFwulUppNW2qVtgbtUgsFov/L5FM7u/ubHxZi0a2p6enPR6P3+9/+K8H/tFR3/jY2G+eInzkeAryMAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7bd8b982d0e624e936c7f4c5392d8e17/e4706/Homey-EN_03.avif 230w", "/en/static/7bd8b982d0e624e936c7f4c5392d8e17/d1af7/Homey-EN_03.avif 460w", "/en/static/7bd8b982d0e624e936c7f4c5392d8e17/7f308/Homey-EN_03.avif 920w", "/en/static/7bd8b982d0e624e936c7f4c5392d8e17/e1c99/Homey-EN_03.avif 1380w", "/en/static/7bd8b982d0e624e936c7f4c5392d8e17/2babf/Homey-EN_03.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7bd8b982d0e624e936c7f4c5392d8e17/a0b58/Homey-EN_03.webp 230w", "/en/static/7bd8b982d0e624e936c7f4c5392d8e17/bc10c/Homey-EN_03.webp 460w", "/en/static/7bd8b982d0e624e936c7f4c5392d8e17/966d8/Homey-EN_03.webp 920w", "/en/static/7bd8b982d0e624e936c7f4c5392d8e17/445df/Homey-EN_03.webp 1380w", "/en/static/7bd8b982d0e624e936c7f4c5392d8e17/fad48/Homey-EN_03.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7bd8b982d0e624e936c7f4c5392d8e17/81c8e/Homey-EN_03.png 230w", "/en/static/7bd8b982d0e624e936c7f4c5392d8e17/08a84/Homey-EN_03.png 460w", "/en/static/7bd8b982d0e624e936c7f4c5392d8e17/c0255/Homey-EN_03.png 920w", "/en/static/7bd8b982d0e624e936c7f4c5392d8e17/b1001/Homey-EN_03.png 1380w", "/en/static/7bd8b982d0e624e936c7f4c5392d8e17/29007/Homey-EN_03.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7bd8b982d0e624e936c7f4c5392d8e17/c0255/Homey-EN_03.png",
              "alt": "Homey",
              "title": "Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`features/ptz/preset     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` - `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "4-use-different-detection-areas-for-each-position",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#4-use-different-detection-areas-for-each-position",
        "aria-label": "4 use different detection areas for each position permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Use different Detection Areas for each Position`}</h3>
    <p>{`In combination with the scheduled day and night position (see above) you can also activate different motion detection areas - in the example the areas 1 & 2 are activated during the day and the areas 3 & 4 at night:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/29007/Homey-EN_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozwGfAmD9AI53RMemI3xpU4t8dIp6bod4a4h5bHpoWWJLN39vZ4FuTI9xD0Y0LmtcVG9iXE5BPD8yLEAzLTwuKDksJgB6YUaCZDFvVEB4X0x9aVh3XUp0V0J5YE2CbmCOemt0X01nSRVJLyVWPzVRPjZLOjNKNy5HNS1fUk5NPTcA3NLN2M7M29DJ2s/G1c/Kz8rEz8vF0sq/wcu+0tHLamJfMSgoQDYxMigklY+NcWtqJSIhTUVA0NfKl52aAP7///////////z9/vb3+ezy9fn///z9+uHo4ff7/GRlZS0vLzAyM09RUvDy9L/BxDE5PT5AQJqfl3R8ewD29vb4+Pj6+vnw8PHGx8rW19j//fz6+fn////y6+tgX18uLy8yMTFMTE3Cxci3uLs8OzszMjIsLS84NDQA////////////////5+bm8Ozs//z8////383Nw6ajZGVkLi0tOTg4Nzc3f4CAbGtrMS8uNTQzNC0rV0VBAMTK0MfU3MnR2MrP183T2tHT2Pj7+93Kyrybk+7x5mNkZSwtLjg6OzU2Njc8QjQ8Qzg/RjxBR0tDSGxydACyqrC+fYOzqrLI09rR2N+xuL/b29zk1dHq8Ofw9+5mY2FBNCZHPTEzMjJaXmVRVWFVWmRGTVxfZ3TAx84A17ex1T0tiFeDX2LK1dHs5+ji19bY////7MWh7OTVenNoXllTZ15RPTUzQE89KIIZLGQeb2oPg4NA5ebpAMSulttxYpJtml9exby+weXk29fX2vXy7MubcNfGsm9rZWRjY2ViXDMsK2JyXV25U3Orabm2VL2/e9/e5QBJRkVPT05NTk1PT0xLTUhFQ0TCwsLs6+nV1dPe3dufn598e3yEhYV8e3uAe3uMh4yNiYyIh4yLjI+LiYqKumi1LZlm+AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/e4706/Homey-EN_04.avif 230w", "/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/d1af7/Homey-EN_04.avif 460w", "/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/7f308/Homey-EN_04.avif 920w", "/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/e1c99/Homey-EN_04.avif 1380w", "/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/2babf/Homey-EN_04.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/a0b58/Homey-EN_04.webp 230w", "/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/bc10c/Homey-EN_04.webp 460w", "/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/966d8/Homey-EN_04.webp 920w", "/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/445df/Homey-EN_04.webp 1380w", "/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/fad48/Homey-EN_04.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/81c8e/Homey-EN_04.png 230w", "/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/08a84/Homey-EN_04.png 460w", "/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/c0255/Homey-EN_04.png 920w", "/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/b1001/Homey-EN_04.png 1380w", "/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/29007/Homey-EN_04.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0e01b5b0ee5a197e6d554b68e46ec2b1/c0255/Homey-EN_04.png",
              "alt": "Homey",
              "title": "Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/area1/enable      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area2/enable      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area3/enable      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area4/enable      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "5-use-different-sensitivities-for-day-and-night",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#5-use-different-sensitivities-for-day-and-night",
        "aria-label": "5 use different sensitivities for day and night permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5. Use different sensitivities for day and night`}</h3>
    <p>{`Since different sensitivities are often required for motion detection during the day and night, you can also adjust them - in the example to 75% during the day and 25% at night:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cc436c97d0199b8ab0a8246a3fd62246/29007/Homey-EN_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozwGfAmD9ADC2vJzEWiC64jrF9D7G8TzF8S/C8D3F8SzC8Cy88Gp8aIuFLTxWY2Z6gW1/hWt9hHGCiF5yeUxiai5JUwBBw+Ngx8hBx/BUzPNMyvJFyPE9xfFHyPIqwfBLx/FdbWtVXDc3TFNKW2FGXWNFYGg/WWJAW2NJYWoxSFAA+fr89/3//Pz99fv94PL45O7x6/X36/f86/f98fj6Y2JjJykuOTk4JCUmdYOIy9XYwMjKzNTYwc/VWmVpAPn5+f38+v38/Pn9/uj09/j19f///+70/Mzi9fD6+2RjYi4vLjw8PCcpKpOeov////39/Pj7/uHv+2VwcwD8/Pz9/v79/f31+fzm8vf7+ff49/e8vL7l8Pnv+ftkY2MqKik3NzQhIiCXoqPa2dfZ2Nj////5/PxibnIAur7Cn5+sqqq2rLzD0uXq8ff44evwusvZ8Pr97vf7ZGJhLjE2PkBLKzE7iJmiwdHfydbe5Ors2eXoXWltAMPJyuaSjsdyda+33Nrg+ePs78nQ0/3///H5/Pn6+2JhYUtQU1dwYllqY0tSSJuln662uzM7PUBJS0JERQC7wrjOaFOkN0I3M7N+erfw8OHKysv////+/fz7+/pkYmUvOCsmlhc2eR+CewK5uX+mpa4mJCI1MzNAPz8AraylrZeOq5eYm5u3o6eqtbSvxsXH////+/v8+/v7Y2Jjbm5nl7mToLWbuLaPzs++lpOXLzAvODg4QUFBAN7e38vOz8XJyKurptva2d3c3vPz8/////7+/vv7+2FhYVFQUWJcYpSQlIyLkmhoa2BgYDExMi4uLjs7OwDu7u718/Pr6+rb293t7e7z8/Lt7e3s7Ozs7Ozu7u6goKB9fX2Bg4GfoJ6ampiAgH+Dg4OFhYWEhISKioo7Had5yWecCAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cc436c97d0199b8ab0a8246a3fd62246/e4706/Homey-EN_05.avif 230w", "/en/static/cc436c97d0199b8ab0a8246a3fd62246/d1af7/Homey-EN_05.avif 460w", "/en/static/cc436c97d0199b8ab0a8246a3fd62246/7f308/Homey-EN_05.avif 920w", "/en/static/cc436c97d0199b8ab0a8246a3fd62246/e1c99/Homey-EN_05.avif 1380w", "/en/static/cc436c97d0199b8ab0a8246a3fd62246/2babf/Homey-EN_05.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cc436c97d0199b8ab0a8246a3fd62246/a0b58/Homey-EN_05.webp 230w", "/en/static/cc436c97d0199b8ab0a8246a3fd62246/bc10c/Homey-EN_05.webp 460w", "/en/static/cc436c97d0199b8ab0a8246a3fd62246/966d8/Homey-EN_05.webp 920w", "/en/static/cc436c97d0199b8ab0a8246a3fd62246/445df/Homey-EN_05.webp 1380w", "/en/static/cc436c97d0199b8ab0a8246a3fd62246/fad48/Homey-EN_05.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cc436c97d0199b8ab0a8246a3fd62246/81c8e/Homey-EN_05.png 230w", "/en/static/cc436c97d0199b8ab0a8246a3fd62246/08a84/Homey-EN_05.png 460w", "/en/static/cc436c97d0199b8ab0a8246a3fd62246/c0255/Homey-EN_05.png 920w", "/en/static/cc436c97d0199b8ab0a8246a3fd62246/b1001/Homey-EN_05.png 1380w", "/en/static/cc436c97d0199b8ab0a8246a3fd62246/29007/Homey-EN_05.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cc436c97d0199b8ab0a8246a3fd62246/c0255/Homey-EN_05.png",
              "alt": "Homey",
              "title": "Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/area1/sensitivity     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` - `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"100"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area2/sensitivity     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` - `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"100"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area3/sensitivity     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` - `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"100"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area4/sensitivity     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` - `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"100"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "6-turn-off-all-cameras-when-you-arrive-home",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#6-turn-off-all-cameras-when-you-arrive-home",
        "aria-label": "6 turn off all cameras when you arrive home permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`6. Turn off all Cameras when you arrive home`}</h3>
    <p>{`Either schedule or manually disable all alarm detection areas and the PIR sensor, if you do not want to trigger an alarm:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2da9234980611b9b019dc81da6001a4f/29007/Homey-EN_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACAklEQVQoz12QuW4TURSGp0DiAeAhoABBSAMSBaKlxGRBFPA4gZcIFRJCQUADVRIHyOYlwoFgJfbYk8zYMzczd/fc9aBxXABfdXR+fbr3P8GlD+jGBr5Tx3ObeL5Orq3je1/xXJ1fXz+er+/c3oxubdCbG1V6d4tc/Zxf+ZJf/nQerKHgPQoebtMnDbbcoAsNutxktQZbbLLFpqztD561WgutuNYql5r88R5dasvltnze5o926P1v5MF3GgCAAygBJgDKw384rXiOzga9qH+Cs7GW/O800B5CIt++W3u9utro9iSA96CsRfn5MBoMT08LQrs/B53dJqH0LBmFw2iUZaJUFiBwAEdR/OLp4suVlXg0LixYrcMwTNNUa33xQpFRFJ1dzNZajHEYhoyxwAMkQh3sbydhN9deWA/eE0q9rzp47zNlifXUQ6ZctZzuKaXGmKqz9lBYSIQm1V8qgRDip3ZcuhPhfjN7SM2JsHHp3PQuhJCZfE4me4dJJC0xVeKc45xH0fC41+cWilJz7Zjx3AKS6qDTkUJwzmfy7lH66k1LGW8vLuy8ECKOk9E4rUpq5Z21xgCA0arf6yulGGOV/GNAd36lH7d6pTIA3k3JsgwhlBcFJoRQihBKRiPKWIFxnucYY4SQ1jqYKMOk4lIba2eqc2VZyiliivwXIcRkMnHO/QE2SEqzfprDWQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2da9234980611b9b019dc81da6001a4f/e4706/Homey-EN_06.avif 230w", "/en/static/2da9234980611b9b019dc81da6001a4f/d1af7/Homey-EN_06.avif 460w", "/en/static/2da9234980611b9b019dc81da6001a4f/7f308/Homey-EN_06.avif 920w", "/en/static/2da9234980611b9b019dc81da6001a4f/e1c99/Homey-EN_06.avif 1380w", "/en/static/2da9234980611b9b019dc81da6001a4f/2babf/Homey-EN_06.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2da9234980611b9b019dc81da6001a4f/a0b58/Homey-EN_06.webp 230w", "/en/static/2da9234980611b9b019dc81da6001a4f/bc10c/Homey-EN_06.webp 460w", "/en/static/2da9234980611b9b019dc81da6001a4f/966d8/Homey-EN_06.webp 920w", "/en/static/2da9234980611b9b019dc81da6001a4f/445df/Homey-EN_06.webp 1380w", "/en/static/2da9234980611b9b019dc81da6001a4f/fad48/Homey-EN_06.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2da9234980611b9b019dc81da6001a4f/81c8e/Homey-EN_06.png 230w", "/en/static/2da9234980611b9b019dc81da6001a4f/08a84/Homey-EN_06.png 460w", "/en/static/2da9234980611b9b019dc81da6001a4f/c0255/Homey-EN_06.png 920w", "/en/static/2da9234980611b9b019dc81da6001a4f/b1001/Homey-EN_06.png 1380w", "/en/static/2da9234980611b9b019dc81da6001a4f/29007/Homey-EN_06.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2da9234980611b9b019dc81da6001a4f/c0255/Homey-EN_06.png",
              "alt": "Homey",
              "title": "Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/area1/enable          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area2/enable          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area3/enable          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area4/enable          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/actions/pir/enable    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "7-use-external-sensors-to-turn-your-camera-when-motion-is-detected",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#7-use-external-sensors-to-turn-your-camera-when-motion-is-detected",
        "aria-label": "7 use external sensors to turn your camera when motion is detected permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`7. Use external Sensors to turn your Camera when Motion is detected`}</h3>
    <p>{`Let your camera move between positions depending on system variables in home automation system. For example - when the door sensor is tripped, look to the door, when the window sensor is triggered turn towards the window and when the external motion sensor detects a person, turn to the kitchen door:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8a7b6d1160804a05cfda2fa401d0468d/29007/Homey-EN_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACU0lEQVQozx2Pu24TQQBFt+QTqJBoUAIRUhpERVDkgGhoKKgQP0BBvgOJhhYoqFCaiKCESEFx7DzWduKEjR3biR/xI/ucx87s7O7szqx3kHOrq3Nuc7W7O/5CicwX/cUyWSjThRKdK5In++TeX//+Lnl6QB8U/bkiWSyTRyX6eJ8+3PPni/6dLaT9gtqyTl9U2UotWqmFS2VcOMTLFVaoBC9r7P1Z+O4sXNKDQiUoVNnzA3+pDAvV8PVJ9KrGnh1RTeVZRIk97OeCDwZGf9xXs+Qqz9RUqkwolee3CDr95pWBbSsm/mwwlZppgxvMrv2wRxITmgOERonyZe4hzGLueL4JKM3VKFEuAT3XbKFoQiJIKCKBFh5XZamolFSS51KoLMlnhQvORBIxijnDuQinMs4zobJU5anKhUyjlDMt3futvnzODF3ANrIakdsQoJF659IzEniZ4JHAwxSPJWxTt0GdpvAaAhjS+8e9piaOS+HGOj0qKdC27IvAMQS6FN217Gg1sSppACRDMiJTfOXZ58ht5qgJryv89FMy3tV45xDUt7zWbuae97qnaHImyUC0vk533iRWJXI68VCPxjXpGubQGPfqinX3Nn90f76dWkUtav1JjfWsven19/WTerPTiIe6aH4TBx+4qcfdMq+vJY2NYKxfdFqHx7V0ote3v99sfxTjHY2jbooHKe7F4cTyMaBYspEIJyk3eWTz2eGB8HucjWwMXUpkOAlQP40nSXSjecgF2AXYA8gBwITQ8rA7g8iB2IM+nKlbC6E1s7ccIMcF9n9xMTMpVS74kAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8a7b6d1160804a05cfda2fa401d0468d/e4706/Homey-EN_07.avif 230w", "/en/static/8a7b6d1160804a05cfda2fa401d0468d/d1af7/Homey-EN_07.avif 460w", "/en/static/8a7b6d1160804a05cfda2fa401d0468d/7f308/Homey-EN_07.avif 920w", "/en/static/8a7b6d1160804a05cfda2fa401d0468d/e1c99/Homey-EN_07.avif 1380w", "/en/static/8a7b6d1160804a05cfda2fa401d0468d/2babf/Homey-EN_07.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8a7b6d1160804a05cfda2fa401d0468d/a0b58/Homey-EN_07.webp 230w", "/en/static/8a7b6d1160804a05cfda2fa401d0468d/bc10c/Homey-EN_07.webp 460w", "/en/static/8a7b6d1160804a05cfda2fa401d0468d/966d8/Homey-EN_07.webp 920w", "/en/static/8a7b6d1160804a05cfda2fa401d0468d/445df/Homey-EN_07.webp 1380w", "/en/static/8a7b6d1160804a05cfda2fa401d0468d/fad48/Homey-EN_07.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8a7b6d1160804a05cfda2fa401d0468d/81c8e/Homey-EN_07.png 230w", "/en/static/8a7b6d1160804a05cfda2fa401d0468d/08a84/Homey-EN_07.png 460w", "/en/static/8a7b6d1160804a05cfda2fa401d0468d/c0255/Homey-EN_07.png 920w", "/en/static/8a7b6d1160804a05cfda2fa401d0468d/b1001/Homey-EN_07.png 1380w", "/en/static/8a7b6d1160804a05cfda2fa401d0468d/29007/Homey-EN_07.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8a7b6d1160804a05cfda2fa401d0468d/c0255/Homey-EN_07.png",
              "alt": "Homey",
              "title": "Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`features/ptz/preset     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` - `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`This can be combined well with the parking position in the camera - the camera then automatically returns to the standard position after x minutes.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "8-use-external-sensors-to-trigger-alarm-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#8-use-external-sensors-to-trigger-alarm-recordings",
        "aria-label": "8 use external sensors to trigger alarm recordings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`8. Use external Sensors to trigger Alarm Recordings`}</h3>
    <p>{`When you set up your camera to turn to a position when triggered by an external sensor, you probably want to start an alarm recording on the internal SD card to catch the intruder. This can be done by starting the manual recording through your home automation system and after a delay following up the stop command. Due to adjustable delay, the length of the alarm video is selectable (15s - 60s):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c7d893c01848cd5230f2b74341acfa2d/29007/Homey-EN_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB60lEQVQozz2OTY7aQBCFfbzcJfeIoiyymhNE2c0CabJLNIomIUNgEDEe82Nsg8C4290Y2+D+cXUbd2RDUvoWr57eU5VlTNNhtNZCSHOb5sxYkuDFcoEwZpxfXSEEIYRSyhgzprH+pQ3nMkYHbkymTKEbVJu8PI5XE8qrrbigE9/mZak0K1nJRNO09yxdbK5AtubEg9RXaVAdVioPIQ8LNFP5Why8HLlZPBPHUB79KgtVsdbFxgJi30gmulirM9EsVXkM1BGJfUocRWygU32wNfql4j7Q6f+KBfS1wwVi1+d9Lc6XitUs0+mcESdBrqYO0Gl19Gv3Tk3ewdGD1mlbVqduiN2oDPps/Vz6P1Xy55Q4wX5eHxxz8sY/HrzeW+N+qNJle7zLW0CmN6gDuwEEj+A/QvQMxN6uJoNBH68GJrW/P3z6+vGNmb2XdN793FYsiYcSDwX6LchYOD0+uueje/nay9HYDb0nN3QXLybqT7599p7umugLxy8CD69YVc1kzXRTthiujewQ6lJmRVqcTqI6N0YSupPAalPpS3kLX0oLSbnhMsY+StZRvMJJiHCwi7wYBwiHMQ53kbePfZpucRLs41XrJ+E2WmZFbEGtZK1AcaV4BUxpAZ246nYFDoqDYq0ArlRrVsB0Lf8CDVJZg47Jc+MAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c7d893c01848cd5230f2b74341acfa2d/e4706/Homey-EN_08.avif 230w", "/en/static/c7d893c01848cd5230f2b74341acfa2d/d1af7/Homey-EN_08.avif 460w", "/en/static/c7d893c01848cd5230f2b74341acfa2d/7f308/Homey-EN_08.avif 920w", "/en/static/c7d893c01848cd5230f2b74341acfa2d/e1c99/Homey-EN_08.avif 1380w", "/en/static/c7d893c01848cd5230f2b74341acfa2d/2babf/Homey-EN_08.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c7d893c01848cd5230f2b74341acfa2d/a0b58/Homey-EN_08.webp 230w", "/en/static/c7d893c01848cd5230f2b74341acfa2d/bc10c/Homey-EN_08.webp 460w", "/en/static/c7d893c01848cd5230f2b74341acfa2d/966d8/Homey-EN_08.webp 920w", "/en/static/c7d893c01848cd5230f2b74341acfa2d/445df/Homey-EN_08.webp 1380w", "/en/static/c7d893c01848cd5230f2b74341acfa2d/fad48/Homey-EN_08.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c7d893c01848cd5230f2b74341acfa2d/81c8e/Homey-EN_08.png 230w", "/en/static/c7d893c01848cd5230f2b74341acfa2d/08a84/Homey-EN_08.png 460w", "/en/static/c7d893c01848cd5230f2b74341acfa2d/c0255/Homey-EN_08.png 920w", "/en/static/c7d893c01848cd5230f2b74341acfa2d/b1001/Homey-EN_08.png 1380w", "/en/static/c7d893c01848cd5230f2b74341acfa2d/29007/Homey-EN_08.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c7d893c01848cd5230f2b74341acfa2d/c0255/Homey-EN_08.png",
              "alt": "Homey",
              "title": "Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`task/video/rec/start     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"15"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"30"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"45"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"60"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
task/video/rec/stop      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "9-interconnect-your-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#9-interconnect-your-cameras",
        "aria-label": "9 interconnect your cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`9. Interconnect your Cameras`}</h3>
    <p>{`Install your cameras so that they always keep an eye on each other by utilizing the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Features/PTZ/"
      }}>{`Park Position`}</a>{` that always returns your camera to a set position after it was moved. This prevents intruders from tampering with your cameras unseen.`}</p>
    <p>{`When one camera detect a motion you can have it contact your home automation system using your camera's `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Alarm_Server/"
      }}>{`Alarmserver Function`}</a>{`. You smarthome can then send a command to other cameras nearby to turn towards the position where the initial camera detected the intruder and start an alarm recording.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/29007/Homey-EN_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACd0lEQVQoz02SW08TURSF58+INNJh5lymndJpCwiFsV56obResV6ggBFEC5VLH7QGIQQTTUR9lfBPjA/yYICov+YzZ0Diw8reyd5n7bXXPtbHvc8cn/xm8cUy9kCW7XdbdHe2CMYKtNY6HB39Yn//gK/7Bxwd/+bFyzWSg8N0X23xYfc9Msjx5OkCxyd/2Pv0Bas6Uebw8Ccraxv0xGK4rkAKied5vNnc4tv3H8w2Z2jOPI7y7uY2rvaQSuFKyYW+OK3V9YijVp3AMoXW7DxTg0P4rhsRGdh2nPHxkJnmHJVKhVKpxHRzjjC8Qr8dP+/zXYd7uUGWZ+eRSmMprRG2zbTr8sD3yfs+ntZorVFKEevtpVavU61WicV6UUqe10d8n0YqFb2Vto3yPCylNFInGPVTEZKed05mFDhCUCkWmahU6HfMBolIiVaKhOcxlkoR+j46mUQ6DlbCU+TTNvl0P1qryBsTzQPTFO/p4fFEmerkJL7so5CxCZICI8QMjQYnEjh9fYQJjTWWEdy/VYmQzwiE1KeEnoe6dIlSLs3O7ltq1TKlkQSN+1PUr2bw1Cmp6ZPxONfTPu32M6zhtDq74gwmF/JsVdcln83QXl9m+9021XKRm+UCi89XqBVDtHTQZ9cOtGK5vUS70z710HGcCMYbY7jJR0dGeL+3x/PGDbobS6xudOh0OvRevIgQMlrVeC2FIBgaZqVZZ3WhgRWtd3Y1/d91i8Ui3e5rHl3zac3eof1ylYcPH5BMJgmCIIrGZykl6SDNVMGnURr8R/gPOmoYGBggDEMuDw+Ry2W5e+c29XqNXC5HPp+PkM1mz1WarySEi3Ad/gJOmKIqlnUBrgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/e4706/Homey-EN_11.avif 230w", "/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/d1af7/Homey-EN_11.avif 460w", "/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/7f308/Homey-EN_11.avif 920w", "/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/e1c99/Homey-EN_11.avif 1380w", "/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/2babf/Homey-EN_11.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/a0b58/Homey-EN_11.webp 230w", "/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/bc10c/Homey-EN_11.webp 460w", "/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/966d8/Homey-EN_11.webp 920w", "/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/445df/Homey-EN_11.webp 1380w", "/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/fad48/Homey-EN_11.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/81c8e/Homey-EN_11.png 230w", "/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/08a84/Homey-EN_11.png 460w", "/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/c0255/Homey-EN_11.png 920w", "/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/b1001/Homey-EN_11.png 1380w", "/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/29007/Homey-EN_11.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/58b0d3f2e525efb766f4b41b2e5fe57c/c0255/Homey-EN_11.png",
              "alt": "Homey",
              "title": "Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`features/ptz/preset     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` - `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

task/video/rec/start     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"15"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"30"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"45"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"60"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
task/video/rec/stop      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`In this example both cameras look at each other - and keep an eye on what is happening behind the other camera. This position is stored as `}<strong parentName="p">{`Position 1`}</strong>{` (`}<em parentName="p">{`number=0`}</em>{`) and set to be the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Features/PTZ/"
      }}>{`Park Position`}</a>{`. When the left camera detects something, the right one turns around towards the potential intruder - stored as `}<strong parentName="p">{`Position 2-8`}</strong>{` (`}<em parentName="p">{`number=1-7`}</em>{`) - and starts a recording. And vice versa...`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "10-scan-for-noise-sources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#10-scan-for-noise-sources",
        "aria-label": "10 scan for noise sources permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`10. Scan for Noise Sources`}</h3>
    <p>{`For example, when triggering the audio detection on your camera, you probably do not know the location of the source of the noise. Here you can trigger a horizontal (hscan) or vertical (vscan) search scan. This must then be manually stopped again with the desired delay:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/29007/Homey-EN_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACBUlEQVQoz3WMzW7TQBDH/W6ceAN4BB6AAxIHTkgIcUIq7RmBEKcKARWVGhXSpk3TuomT9CNrx07iXcd26jixvbuzHzZy20OFxE8/zYz+mhmjqsoH3qO1TtN0cROHYZgkCWO0TktdaV3X8n7fqP7PBJHRufXw6T8YMnXvFInDYyQWNsQIEketvWRqR8gSS1ssXcF86fXk8ES6PYmHcjmWqWtA2K2dmyJBchXKfCFSwuPhym1NJ6c2udCLnghMGDXB2oHv78WPLd7fu7syIOrXhl25mii61jxXxVIkiAwb5qB1OeqMzV/ZvCsb27K1VW4/0Yevde5AZEHUN26bBXGfTtsZOsjHrcw+YMRk5OxseHR5fbryjnNsys5usb/hbj7uf3nWO9rRNwOILAPC3q0WBCbYDbAbfLwvwm5OzifO1Z9mc+6cqKADuAuHWxebj76+ffryxfMCn4nIMljQZkGbzjvUbRTHnwvzW9H+xHHTn1mmh/eHzgBZ0t5j+Jhd/ayuP5q7H969eXUz/s3DjsFVTmUmy0xVhaiYrKWqyrlcR3G4Wq8oz1SVS50JXehK+8RDzmCxxLqiRshZwGAejr3pZRR7Ph7N/JFP0AyjKJ7NsO0T5BOEAwcTFASIBDaHpSrXQqUGV4IpIURB6VooClDUigIgF5LWgyg45He5EAXnmVRMl6BL+AuKfFQL0MTPKwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/e4706/Homey-EN_09.avif 230w", "/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/d1af7/Homey-EN_09.avif 460w", "/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/7f308/Homey-EN_09.avif 920w", "/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/e1c99/Homey-EN_09.avif 1380w", "/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/2babf/Homey-EN_09.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/a0b58/Homey-EN_09.webp 230w", "/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/bc10c/Homey-EN_09.webp 460w", "/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/966d8/Homey-EN_09.webp 920w", "/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/445df/Homey-EN_09.webp 1380w", "/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/fad48/Homey-EN_09.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/81c8e/Homey-EN_09.png 230w", "/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/08a84/Homey-EN_09.png 460w", "/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/c0255/Homey-EN_09.png 920w", "/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/b1001/Homey-EN_09.png 1380w", "/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/29007/Homey-EN_09.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ce9f7b8a1c3fc271635c0e5429eb8668/c0255/Homey-EN_09.png",
              "alt": "Homey",
              "title": "Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`features/ptz/panscan      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
features/ptz/tiltscan     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "11-manually-disable-all-alarm-functions-for-all-your-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#11-manually-disable-all-alarm-functions-for-all-your-cameras",
        "aria-label": "11 manually disable all alarm functions for all your cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`11. Manually disable all alarm functions for all your cameras`}</h3>
    <p>{`If you have more than one alarm trigger activated in your camera - PIR sensor, alarm zones, audio detection, alarm input - you can activate and deactivate them simultaneously via the alarm schedule without having to address each trigger individually:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f04d67b18949b5f1114e516a7e7f3ce0/29007/Homey-EN_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACGklEQVQoz1WQyWoUURSGa+tCQQRXuo1ogjs39hO4EDqt4BMILgTJSnwmCegqxqm7zSCmMwdjBtOTXcOtqnvrnlv3VHXXnaQbhPivDnzffxa/d+sTW2jzO83sbovfa/Ebq+zaB3p9lV1dobc/i9rGcL55ONeM77dhoc3nW9nczLz5kV1ZYd6jH9DowJOOqG/nj7dEbVPUvue1TfFgnT/bwecHYX3ruNFJFjtycTuvb8vGLj7dkbUNeLgOnrPGXY5WbozO2eltlDP6MrSqUih1Vc2o9kaibHV2N49+CeOUUkEU9wbDhGWVMUprgpO1vaPWzj6dGGutH0a9wSCMSKX1RGtvvzt8/fLFl69Nqlwh85CQ0ciPk6QoCmVsL+Vvll69W16mlUPEOI79kR/HMSIqpbwgL067XSJLn/I8FzLjJz93ZZlzDsA5HauTXi8COWJCACDCwdnB1BOCMeYZowvrSuOEEFprrHRMk9IYozVjzBhTWDe2DgCM1qU2EadS2RnNPFZWxUQ552QuiMCgnC40QCWKkmeZtbaq9Hj2mmPRx6k5LDTHAnjm/aHybftCGScAjk9OsSydc1GSdvt9zrm19v1a94xgjvLs/JyL3DlHOfy+uMiyzBtPVGsv6JEx5zwMAgAQQlBKI0IEQJ/gt8MQUKHMwzCEWRhjURRNy0mSJClNUkoI8X3fDwL/XyhNk5SmKU0pne78PyWE/AU/7kHrucqZyAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f04d67b18949b5f1114e516a7e7f3ce0/e4706/Homey-EN_10.avif 230w", "/en/static/f04d67b18949b5f1114e516a7e7f3ce0/d1af7/Homey-EN_10.avif 460w", "/en/static/f04d67b18949b5f1114e516a7e7f3ce0/7f308/Homey-EN_10.avif 920w", "/en/static/f04d67b18949b5f1114e516a7e7f3ce0/e1c99/Homey-EN_10.avif 1380w", "/en/static/f04d67b18949b5f1114e516a7e7f3ce0/2babf/Homey-EN_10.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f04d67b18949b5f1114e516a7e7f3ce0/a0b58/Homey-EN_10.webp 230w", "/en/static/f04d67b18949b5f1114e516a7e7f3ce0/bc10c/Homey-EN_10.webp 460w", "/en/static/f04d67b18949b5f1114e516a7e7f3ce0/966d8/Homey-EN_10.webp 920w", "/en/static/f04d67b18949b5f1114e516a7e7f3ce0/445df/Homey-EN_10.webp 1380w", "/en/static/f04d67b18949b5f1114e516a7e7f3ce0/fad48/Homey-EN_10.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f04d67b18949b5f1114e516a7e7f3ce0/81c8e/Homey-EN_10.png 230w", "/en/static/f04d67b18949b5f1114e516a7e7f3ce0/08a84/Homey-EN_10.png 460w", "/en/static/f04d67b18949b5f1114e516a7e7f3ce0/c0255/Homey-EN_10.png 920w", "/en/static/f04d67b18949b5f1114e516a7e7f3ce0/b1001/Homey-EN_10.png 1380w", "/en/static/f04d67b18949b5f1114e516a7e7f3ce0/29007/Homey-EN_10.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f04d67b18949b5f1114e516a7e7f3ce0/c0255/Homey-EN_10.png",
              "alt": "Homey",
              "title": "Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/schedule/master/sunday    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` - `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Set the alarm schedule for each day of the week from Monday through Sunday. Each `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`P`}</code>{` is half an hour of the day the alarm schedule is active (`}<em parentName="p">{`48 x 30min = 24h`}</em>{`). An `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`N`}</code>{` equals a 30-minute period in which it is disabled. The schedule activates / deactivates all alarm actions.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "12-switch-between-pir-and-software-motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#12-switch-between-pir-and-software-motion-detection",
        "aria-label": "12 switch between pir and software motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12. Switch between PIR and Software Motion Detection`}</h3>
    <p>{`Most of our new cameras have both an internal, software-based motion detection and a hardware PIR (passive infrared) heat sensor to detect motion of objects that have a different surface temperature as the ambient. In most cases we recommend that you `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Actions/"
      }}>{`link both`}</a>{` as they have different strengths and weaknesses and the coupling can filter many sources of false alerts.`}</p>
    <p>{`But there are situation where one of those two ways to detect motion simply fails and does not provide any filtering effect anymore. Examples are:`}</p>
    <ul>
      <li parentName="ul">{`The PIR is exposed to direct sunlight through most of the day. Shadows and temperature changes trigger regular false alarms.`}</li>
      <li parentName="ul">{`The Motion Detection Areas are constantly triggered at night due to the light from passing cars.`}</li>
      <li parentName="ul">{`etc.`}</li>
    </ul>
    <p>{`We can use our smarthome to automatically deactivate the problematic sensor during times where we know that their accuracy is affected. Since we probably want to use both sensors during times of the day when both work perfectly, we also have to switch the coupling on and off, accordingly.`}</p>
    <p><strong parentName="p">{`Link between PIR and Alarm Areas`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/actions/pir/link	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><strong parentName="p">{`Alarm Areas Activation / Deactivation`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/area1/enable          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area2/enable          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area3/enable          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
alarm/area4/enable          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><strong parentName="p">{`PIR Activation / Deactivation`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/actions/pir/enable    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      